<template>
  <div>
    <b-card v-if="false" bg-variant="light" no-body class="overflow-hidden">
      <b-card-body title="Новый набор на курс по BPMN! Начало 10 июня">
        <b-card-text>
          Практический онлайн-курс для всех, кто хочет разобраться в BPMN и научиться составлять выразительные и понятные схемы бизнес-процессов. Не скучная теория, а практические задания в группах.
        </b-card-text>
        <b-btn-group>
          <b-button
            target="_blank"
            href="https://bpmn2.ru/online-course-fast-bpmn-in-practice"
            variant="danger"
            >Узнать подробности</b-button
          >
          <b-button class="ml-2" variant="dark" @click="setShowAd">
            {{ $t("close") }}
          </b-button>
        </b-btn-group>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "FrontendShowCourseCard",

  data() {
    return {
      showAd3: false,
    };
  },

  computed: {
    enterpriseEnabled() {
      try {
        return this.$store.getters.getEnterpriseEnabled;
      } catch {
        return false;
      }
    },
  },

  mounted() {
    if (localStorage.getItem("showAd3") !== null) {
      this.showAd3 = localStorage.getItem("showAd3") === "true";
    } else {
      this.showAd3 = true;
    }
  },

  methods: {
    setShowAd() {
      this.showAd3 = false;
      localStorage.setItem("showAd3", false);
    },
  },
};
</script>

<style lang="scss" scoped></style>
